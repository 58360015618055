import React, { useEffect, useState, useCallback } from "react";
import {
    Col,
    Collapse,
    Container,
    Modal,
    ModalBody,
  } from "reactstrap";
  
import ReactLoader from '../component/ReactLoader';
import InviteIco from "../assets/img/invite.webp";
import StakeIco from "../assets/img/buy.webp";
import TeamIco from "../assets/img/team.webp";
import BusinessIco from "../assets/img/buss.webp";
import EarningsIcon from "../assets/img/inc.webp";
import ProfileIco from "../assets/img/profile.webp";
import YTIco from "../assets/img/youtube.webp";
import TwitterIco from "../assets/img/twitter.webp";
import FbIco from "../assets/img/fb.webp";
import TgIco from "../assets/img/telegram.webp";
import StatementIco from "../assets/img/details.webp";
import WalletIco from "../assets/img/wallet.webp";
import Header from "../common/header";
import { Link } from "react-router-dom";
import axios from 'axios';
import Swal from 'sweetalert2'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { ScratchCard } from 'next-scratchcard';
import './my.css';
import sh from '../assets/img/scard.webp'
const config = require('../config.json')
  export default function Home( {ipAddress, loginData }) {
    const [loading, setLoading] = useState(false);
    const [dash, setDash] = useState({})
    const [appdata] = useState(JSON.parse(localStorage.getItem('appData')))
    const [faqs, setFaq] = useState({})
    const [activeFAQ, setActiveFAQ] = useState(-1);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const toggleFAQ = (faq) => {
      if (faq === activeFAQ) {
        setActiveFAQ(-1);
      } else {
        setActiveFAQ(faq);
      }
    };
    const handleComplete = () => {
      console.log('Scratch card completed!');
      let data = JSON.stringify({
        "address": loginData.address,
        "ip": ipAddress,
        "ulid": loginData.ulid
    });

    let axiosConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${config.baseUrl}scard`,
        headers: {
            'address': loginData?.address,
            'ip': ipAddress,
            'ulid': loginData.ulid,
            'auth': loginData.auth,
            'token': loginData.token,
            'Content-Type': 'application/json'
        },
        data: data
    };
    axios.request(axiosConfig)
        .then((response) => {
            console.log(response.data)
            if (response.data?.status) {
                
                toast.info(response.data?.message);
                setModal(false)
            }
            else {
                Swal.fire({
                    icon: 'warning',
                    title: 'BlockHASH',
                    text: response.data?.message
                });
                setModal(false)
            }
        })
        .catch((error) => {
            console.log(error);
        });
    };
    const handleDash = useCallback(async () => {
      
      setLoading(true)
      let data = JSON.stringify({
          "address": loginData.address,
          "ip": ipAddress,
          "ulid": loginData.ulid
      });

      let axiosConfig = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${config.baseUrl}desk`,
          headers: {
              'address': loginData?.address,
              'ip': ipAddress,
              'ulid': loginData.ulid,
              'auth': loginData.auth,
              'token': loginData.token,
              'Content-Type': 'application/json'
          },
          data: data
      };
      axios.request(axiosConfig)
          .then((response) => {
              console.log(response.data)
              if (response.data?.status) {
                  
                  setDash(response.data?.info)
                  setModal(response.data?.info?.card)
              }
              else {
                  Swal.fire({
                      icon: 'warning',
                      title: 'BlockHASH',
                      text: response.data?.message
                  });
              }

              setLoading(false)
          })
          .catch((error) => {
              console.log(error);
          });
  }, [ipAddress, loginData.address, loginData.auth, loginData.token, loginData.ulid]);
  const setCopy = (e) =>{
    toast.info("Referral Code Copy to your clipboard")
  }
  useEffect(() => {
      handleDash()
      if(appdata?.faq)
      {
        setFaq(appdata)
      }
  }, [appdata, handleDash])

  
    return (
        <div>
            <Header/>
            {loading ? <><ReactLoader/></> :
            <>
            <div className="mb-3 mb-md-0"  style={{ overflowY: "auto",  marginBottom: "50px",}} >
                <div className="dashboard mb-5 mb-md-0 main-p">
                  <Container className="p-0">
                    <Col md="12">
                      <div className="card mt-2">
                        <div className="card__body border-radius-all">
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="mb-0 filter__card-label">Welcome </p>
                            <p className="mb-0 filter__card-label text-site-primary">
                              {dash?.name}
                            </p>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mt-1">
                            <p className="mb-0 filter__card-label">Date of Invested:</p>
                            <p className="mb-0 filter__card-label text-site-primary">
                            {dash?.doa}
                            </p>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mt-1">
                            <p className="mb-0 filter__card-label">Miner Capital</p>
                            <p className="mb-0 filter__card-label">
                              $ {dash?.total_invest}
                              
                            </p>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mt-1">
                            <p className="mb-0 filter__card-label">Your Referral Code</p>
                            <p className="mb-0 filter__card-label text-primary">
                              {dash?.sponsor_id}
                              
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Container>
                  <Container className="p-0">
                    <Col md="12">
                      <div className="card mt-2 ">
                        <div className="card__body border-radius-all">
                          <div className="d-flex align-items-center justify-content-between">
                            <p className="mb-0 filter__card-label">Total Leverage</p>
                            <p className="mb-0 filter__card-label "> Leverage Used</p>
                          </div>
                          <div className="d-flex align-items-center justify-content-between mt-1">
                            <h6 className="mb-0  text-site-primary number">
                              $ {dash?.limit}
                            </h6>
                            <h6 className="mb-0   text-site-primary number">
                              $ {dash?.used}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Container>
                  <Container className="mt-2 dashboard__card px-0 px-sm-4 shadow-sm">
                    <div className=" dashboard__menu ">
                    <CopyToClipboard text={dash?.copyLink}
                        onCopy={() => setCopy(true)}
                    >
                      <div className="dashboard__menu-item">
                        <div className="dashboard__menu-item__content">
                          {/* <div className="dashboard__menu-item__content-icon"></div> */}
                          
                          <img src={InviteIco}  alt="invite"  className="dashboard__menu-item__content-ico" />
                          Invite
                          
                        </div>
                      </div>
                    </CopyToClipboard>
                      <Link to={"/miner"}>
                      <div className="dashboard__menu-item">
                        <div  className="dashboard__menu-item__content">
                          <img src={StakeIco} alt="stake"  className="dashboard__menu-item__content-ico"/>
                          Miner
                        </div>
                      </div>
                      </Link>
                      <Link to="/topup">
                      <div className="dashboard__menu-item" style={{ borderBottom:  ""}} >
                        <div className="dashboard__menu-item__content" >
                          <img src={WalletIco} alt="wallet" className="dashboard__menu-item__content-ico"/>
                          TopUp
                        </div>
                      </div>
                      </Link>
                      <Link to={'/team'}>
                      <div className="dashboard__menu-item">
                        <div className="dashboard__menu-item__content">
                          <img  src={TeamIco}
                            alt="team"
                            className="dashboard__menu-item__content-ico"
                          />
                          Team
                        </div>
                      </div>
                      </Link>
                      <Link to={'/buss'}>
                      <div className="dashboard__menu-item">
                        <div
                          className="dashboard__menu-item__content"
                          
                        >
                          <img
                            src={BusinessIco}
                            alt="business"
                            className="dashboard__menu-item__content-ico"
                          />
                          Business
                        </div>
                      </div>
                      </Link>
                      <Link to ={"/reward"}>
                      <div className="dashboard__menu-item">
                        <div
                          className="dashboard__menu-item__content"
                          
                        >
                          <img
                            src={EarningsIcon}
                            alt="earnings"
                            className="dashboard__menu-item__content-ico"
                          />
                          Earnings
                        </div>
                      </div>
                      </Link>
                      <Link to={'/profile'}>
                      <div
                        className="dashboard__menu-item"
                        style={{
                          borderBottom:  "",
                        }}
                      >
                        
                        <div
                          className="dashboard__menu-item__content"
                          
                        >
                          <img
                            src={ProfileIco}
                            alt="profile"
                            className="dashboard__menu-item__content-ico"
                          />
                          Profile
                        </div>
                      </div>
                      </Link>
                      <Link to={'/wallet'}>
                      <div
                        className="dashboard__menu-item"
                        style={{
                          borderBottom:  "",
                        }}
                      >
                        <div
                          className="dashboard__menu-item__content"
                          
                        >
                          <img
                            src={WalletIco}
                            alt="wallet"
                            className="dashboard__menu-item__content-ico"
                          />
                          Wallet
                        </div>
                      </div>
                      </Link>
                      <div
                        className="dashboard__menu-item"
                        style={{
                          borderBottom:  "",
                        }}
                        
                      >
                        <Link to ="/statment">
                        <div
                          className="dashboard__menu-item__content"
                          
                        >
                          <img
                            src={StatementIco}
                            alt="statement"
                            className="dashboard__menu-item__content-ico"
                          />
                          Statement
                        </div>
                        </Link>
                      </div>
                      <div className="dashboard__menu-item">
                        <a
                          href="#"
                          //target="_blank"
                          rel="noreferrer"
                          className="text-decoration-none"
                        >
                          <div className="dashboard__menu-item__content">
                            <img
                              src={FbIco}
                              alt="nft"
                              className="dashboard__menu-item__content-ico"
                            />
                            Facebook
                          </div>
                        </a>
                      </div>
                      <div className="dashboard__menu-item">
                        <a
                          href="#"
                          //target="_blank"
                          rel="noreferrer"
                          className="text-decoration-none"
                        >
                          <div className="dashboard__menu-item__content">
                            <img
                              src={TgIco}
                              alt="nft"
                              className="dashboard__menu-item__content-ico"
                            />
                            Telegram
                          </div>
                        </a>
                      </div>
                      <div className="dashboard__menu-item">
                        <a
                          href="#"
                          //target="_blank"
                          rel="noreferrer"
                          className="text-decoration-none"
                        >
                          <div className="dashboard__menu-item__content">
                            <img
                              src={TwitterIco}
                              alt="nft"
                              className="dashboard__menu-item__content-ico"
                            />
                            Twitter
                          </div>
                        </a>
                      </div>
                      
                      
                      <div className="dashboard__menu-item">
                        <a
                          href="#"
                          //target="_blank"
                          rel="noreferrer"
                          className="text-decoration-none"
                        >
                          <div className="dashboard__menu-item__content">
                            <img
                              src={YTIco}
                              alt="nft"
                              className="dashboard__menu-item__content-ico"
                            />
                            Youtube
                          </div>
                        </a>
                      </div>
                      
                      
                    </div>
                  </Container>
                  <Container className="dashboard__card mt-2 shadow-sm">
                    <h2 className="text-center mb-0 dashboard__card__heading fw-semibold text-uppercase">
                      FREQUENTLY ASK QUESTION
                    </h2>
                    <Container className="px-0">
                      {
                        faqs?.faq &&
                        faqs?.faq?.map((f, index) => (
                          <div className="card mt-2" key={index+1}>
                            <div
                              className="card__header d-flex align-items-center justify-content-between border-radius-top pointer"
                              onClick={() => toggleFAQ(index)}
                            >
                              <p className="mb-0 filter__card-label">
                                {f?.quest}
                              </p>
                              {activeFAQ === index ? (
                                <i className="fa fa-chevron-up"></i>
                              ) : (
                                <i className="fa fa-chevron-down"></i>
                              )}
                            </div>
                            <Collapse isOpen={activeFAQ === index}>
                              <div className="card__body border-radius-bottom">
                                <small
                                  className="filter__card-label"
                                  dangerouslySetInnerHTML={{ __html : f?.ans}}
                                ></small>
                              </div>
                            </Collapse>
                          </div>
                        ))
                      }
                    </Container>
                  </Container>
                </div>
            </div>
            <Modal isOpen={modal} toggle={toggle} className="modal-dialog-right">
           <ModalBody className="text-center">
              <ScratchCard finishPercent={50} brushSize={40} onComplete={handleComplete} width={330} height={200} image={sh}>
              {/* <img
                height={200}
                width={330}
                src="https://images.pexels.com/photos/14686115/pexels-photo-14686115.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                alt="test"
              />    */}
               <div style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              >
          <h6>You wone : {parseFloat(dash?.cardInfo?.c_amt).toFixed(2)}</h6>
        </div>
              </ScratchCard>
            </ModalBody>
        </Modal>
        </>
            }

        </div>
    );
  }