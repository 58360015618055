import React, { useEffect, useState, useCallback } from "react";
import {
    Button,
    Col,
    Container,
    Input,
    Row,
  } from "reactstrap";
import ReactLoader from '../component/ReactLoader';
import Header from "../common/header";
// import { Link } from "react-router-dom";
import DollarIco from "../assets/img/icon.webp";
import axios from 'axios';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
const config = require('../config.json')
  export default function Wallet({ipAddress, loginData }) {
    const [loading, setLoading] = useState(false)
    const [trans, setTran] = useState(false)
    const [widrl, setwidrl] = useState(false)
    const [businessData, setBusinessData] = useState({})
    const [showWl, setshowwl] = useState(true)
    const [tranamt, setTranamt] = useState(0)
    const [withamt, setWithamt] = useState(0)
    const [showSl, setshowSl] = useState(false)
    const handelTeam = useCallback(async() => {
        setLoading(true)
      let data = JSON.stringify({
          "address": loginData.address,
          "ip": ipAddress,
          "ulid": loginData.ulid
      });

      let axiosConfig = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${config.baseUrl}wallet`,
          headers: {
              'address': loginData?.address,
              'ip': ipAddress,
              'ulid': loginData.ulid,
              'auth': loginData.auth,
              'token': loginData.token,
              'Content-Type': 'application/json'
          },
          data: data
      };
      axios.request(axiosConfig)
          .then((response) => {
              console.log(response.data)
              if (response.data?.status) {
                  
                setBusinessData(response.data?.info)
                setTran(response.data?.info?.is_self)
                setwidrl(response.data?.info?.is)
                
              }
              else {
                  Swal.fire({
                      icon: 'warning',
                      title: 'BlockHASH',
                      text: response.data?.message
                  });
              }

              setLoading(false)
          })
          .catch((error) => {
              console.log(error);
          });
    },[ipAddress, loginData.address, loginData.auth, loginData.token, loginData.ulid])

    const handerWh =(e) => {
      e.preventDefault();
      setshowwl(true);
      setshowSl(false);
     }
    const handerSl =(e) => {
      e.preventDefault();
      setshowwl(false);
     setshowSl(true);
     }

     const handerWith= (e) => {
      e.preventDefault();
      let min = parseFloat(businessData?.minwr);
      setWithamt(parseFloat(withamt))
      if(withamt < min)
      {
        toast.warn("Please enter valid withdrawal amount");
        return;
      }
      let data = JSON.stringify({
          "address": loginData.address,
          "ip": ipAddress,
          "ulid": loginData.ulid,
          "amt" :  withamt
      });
      setLoading(true)
      let axiosConfig = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${config.baseUrl}request`,
          headers: {
              'address': loginData?.address,
              'ip': ipAddress,
              'ulid': loginData.ulid,
              'auth': loginData.auth,
              'token': loginData.token,
              'Content-Type': 'application/json'
          },
          data: data
      };
      axios.request(axiosConfig)
          .then((response) => {
              console.log(response.data)
              setLoading(false)
              if (response.data?.status) {
                toast.info(response.data?.message);
                setWithamt(0);
                handelTeam();                                
              }
              else {
                  Swal.fire({
                      icon: 'warning',
                      title: 'BlockHASH',
                      text: response.data?.message
                  });
              }

              setLoading(false)
          })
          .catch((error) => {
              console.log(error);
          });
     }
     const handerTran= (e) => {
      e.preventDefault();
      let min = parseFloat(businessData?.minslef);
      setTranamt(parseFloat(tranamt))
      if(tranamt < min)
      {
        toast.warn("Please enter valid Transfer amount");
        return;
      }
      let data = JSON.stringify({
          "address": loginData.address,
          "ip": ipAddress,
          "ulid": loginData.ulid,
          "amt" :  tranamt
      });
      setLoading(true)
      let axiosConfig = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${config.baseUrl}transfer`,
          headers: {
              'address': loginData?.address,
              'ip': ipAddress,
              'ulid': loginData.ulid,
              'auth': loginData.auth,
              'token': loginData.token,
              'Content-Type': 'application/json'
          },
          data: data
      };
      axios.request(axiosConfig)
          .then((response) => {
              console.log(response.data)
              setLoading(false)
              if (response.data?.status) {
                toast.info(response.data?.message);
                setTranamt(0);
                handelTeam();                                
              }
              else {
                  Swal.fire({
                      icon: 'warning',
                      title: 'BlockHASH',
                      text: response.data?.message
                  });
              }

              setLoading(false)
          })
          .catch((error) => {
              console.log(error);
          });
     }
    useEffect(() => {
        handelTeam()
    }, [handelTeam])
    return (
        <div>
            <Header/>
            {loading ? <><ReactLoader/></> :
            <div className="mb-3 mb-md-0"  style={{ overflowY: "auto",  marginBottom: "50px"}} >
                <div className="dashboard mb-5 mb-md-0 main-p">
                  <Container className="p-0">
                    <div className="mb-2">
                        <Button className="bg-site-primary" block style={{color:"white"}}>
                            Wallet
                        </Button>
                    </div>
                    <Row>
                      
                        <Col md="6">
                            <div className="card mb-2">
                                <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                    <p className="mb-0 filter__card-label">Reward Wallet Balance</p>
                                    <h6
                                        className="mb-0  text-site-primary"
                                        style={{ fontSize: "17px" }}
                                    >
                                       ${businessData?.main}
                                    </h6>
                                    </div>
                                    <img src={DollarIco} alt="dollar" height="35px" />
                                </div>
                                </div>
                            </div>
                        </Col>
                        
                       
                        <Col md="6">
                            <div className="card mb-2">
                                <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                    <p className="mb-0 filter__card-label">TopUp Wallet Balance</p>
                                    <h6
                                        className="mb-0  text-site-primary"
                                        style={{ fontSize: "17px" }}
                                    >
                                        ${businessData?.top}
                                        
                                    </h6>
                                    </div>
                                    <img src={DollarIco} alt="dollar" height="35px" />
                                </div>
                                </div>
                            </div>
                        </Col>
                        
                        { (trans)?
                        <Col md="6" className="mt-2 mt-md-0" >
                            <div className=" card h-100 mt-2">
                                <div className=" card__header border-radius-top">
                                    Fund Transfer Request to TopUp Wallet
                                </div>
                                <div className=" card__body border-radius-bottom">
                                
                                    <div className="d-flex align-items-center justify-content-between">
                                    <h2 className="mb-2  card__body-label">
                                        Reward Wallet
                                    </h2>
                                    <h2 className="mb-2  card__body-label text-site-primary">
                                      ${businessData?.main}
                                    </h2>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mt-1">
                                    <Input
                                        placeholder="0"
                                        className="m-2"
                                        required
                                        onChange={(e) => setTranamt(e.target.value)}
                                        value={tranamt}
                                    />
                                    </div>
                                    <div className="text-center mt-3">
                                    <Button
                                        className="bg-primary px-5 text-white"
                                        onClick={handerTran}
                                        type="submit"
                                    >
                                        Transfer Now
                                    </Button>
                                    <p className="mt-2 mb-2  card__body-note">
                                        Note: Can't Withdraw TopUp Wallet Balance<br/>
                                        Minimum Transfer : ${businessData?.minslef}
                                    </p>
                                    </div>
                                
                                </div>
                            </div>
                        </Col>
                        :<></> }
                         { (widrl)?
                        <Col md="6" className="mt-2 mt-md-0">
                            <div className=" card h-100 mt-2">
                                <div className=" card__header border-radius-top">
                                    Withdrawal Request to Address
                                </div>
                                <div className=" card__body border-radius-bottom">
                                
                                    <div className="d-flex align-items-center justify-content-between">
                                    <h2 className="mb-2  card__body-label">
                                        Reward Wallet
                                    </h2>
                                    <h2 className="mb-2  card__body-label text-site-primary">
                                      ${businessData?.main}
                                    </h2>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mt-1">
                                    <Input
                                        placeholder="0"
                                        className="m-2"
                                        required
                                        onChange={(e) => setWithamt(e.target.value)}
                                        value={withamt}
                                    />
                                    </div>
                                    <div className="text-center mt-3">
                                    <Button
                                        className="bg-primary px-5 text-white"
                                        onClick={handerWith}
                                        type="submit"
                                    >
                                        Request Now
                                    </Button>
                                    <p className="mt-2 mb-2  card__body-note">
                                        Note: Minimum Withdrawal Amount : $ {businessData?.minwr}
                                    </p>
                                    </div>
                                
                                </div>
                            </div>
                        </Col>
                        :<></> }
                    </Row>
                    <Row>
                        <Col md="12" className="mt-2 mt-md-0">
                            <div className=" h-100 mt-2">
                                
                                    <div className="d-flex align-items-center justify-content-between mt-1">
                                    <Button
                                        className="bg-primary text-white me-2 btn-sm"
                                        style={{fontSize: '10px'}}
                                        type="submit"
                                        onClick={handerWh}
                                    >
                                        Withdrawal History
                                    </Button>
                                    <Button
                                        className="bg-primary text-white "
                                        style={{fontSize: '10px'}}
                                        type="submit"
                                        onClick={handerSl}
                                    >
                                        Transfer history
                                    </Button>
                                    
                                    </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                    {(showWl)?
                        businessData?.payout?.map((pay, idx)=>(
                          <Col md="4" className="mt-2" key={idx+1}>
                          <div className="small-card-ns h-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <h2 className="mb-0 withdraw-card__body-label">
                                Date
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label">
                                {pay?.dt}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Transfer Amount
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                ${pay?.amt}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Tax
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                ${pay?.tax}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Net Amount
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                ${pay?.net}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Status
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label ">
                                {pay?.status}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Tx-hash:
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                <a
                                  href={`https://bscscan.com/tx/${pay?.th}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View Transaction
                                </a>
                              </h2>
                            </div>
                          </div>
                        </Col>
                        ))
                        : <> </>}
                    {/* {(showth)?
                        businessData?.transfer?.map((tp, idx)=>(
                        <Col md="4" className="mt-2" key={2}>
                          <div className="small-card-ns h-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <h2 className="mb-0 withdraw-card__body-label">
                                Date
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label">
                                {tp.dt}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Transfer Amount
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                ${tp?.amt}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                               To : {tp?.user_lid}
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                {tp.user_name} ({tp.user_mobile})
                              </h2>
                            </div>
                          </div>
                        </Col>
                        ))
                        : <> </>}
                    {(showrh)?
                        businessData?.recive?.map((tp, idx)=>(
                        <Col md="4" className="mt-2" key={2}>
                          <div className="small-card-ns h-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <h2 className="mb-0 withdraw-card__body-label">
                                Date
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label">
                                {tp.dt}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Transfer Amount
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                ${tp?.amt}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                               From : {tp?.user_lid}
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                {tp.user_name} ({tp.user_mobile})
                              </h2>
                            </div>
                          </div>
                        </Col>
                        ))
                        : <> </>} */}
                    {(showSl)?
                        businessData?.self?.map((tp, idx)=>(
                        <Col md="4" className="mt-2" key={2}>
                          <div className="small-card-ns h-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <h2 className="mb-0 withdraw-card__body-label">
                                Date
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label">
                                {tp.dt}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Self Transfer Amount
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                ${parseFloat(tp?.amt).toFixed(3)}
                              </h2>
                            </div>
                          </div>
                        </Col>
                        ))
                        : <> </>}
                    </Row>
                  </Container>

                </div>
            </div>
            }
        </div>
    );
  }