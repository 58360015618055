import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import "./assets/scss/custom.scss";
import Home from './pages/home';
import Statement from './pages/statment';
import Reward from './pages/reward';
import Mining from './pages/rewards/mining';
import Community from './pages/rewards/communit';
import Vip from './pages/rewards/vip';
import Global from './pages/rewards/global';
import Support from './pages/support';
import Miner from './pages/miner';
import Direct from './pages/rewards/direct';
import Team from './pages/team';
import Business from './pages/buss';
import Profile from './pages/profile';
import Wallet from './pages/wallet';
import Buy from './pages/buy';
import Topup from './pages/topup';
import Login from './auth/login';
import Register from './auth/register';
import Forgot from './auth/forgot';
import PrivateRoute from './auth/PrivateRoute';
import Soon from './pages/soon';
import Comming from './pages/comming';

function App() {
  const loginData = JSON.parse(localStorage.getItem('loginData'));
  const [ipAddress] = useState('1:1:1:1');
  const [token, setToken] = useState('');
  const handleLogin = (token) => {
    setToken(token);
  };
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoute/>}>
          <Route path="/home" element={<Home ipAddress={ipAddress} loginData={loginData}/>}></Route>
          <Route path="/statment" element={<Statement ipAddress={ipAddress} loginData={loginData}/>}></Route>
          <Route path='/reward' element={<Reward ipAddress={ipAddress} loginData={loginData}/>}/>
          <Route path='/mining' element={<Mining ipAddress={ipAddress} loginData={loginData}/>}/>
          <Route path='/direct' element={<Direct ipAddress={ipAddress} loginData={loginData}/>}/>
          <Route path='/community' element={<Community ipAddress={ipAddress} loginData={loginData}/>}/>
          <Route path='/vip' element={<Vip ipAddress={ipAddress} loginData={loginData}/>}/>
          <Route path='/global' element={<Global ipAddress={ipAddress} loginData={loginData}/>}/>
          <Route path='/support' element={<Support ipAddress={ipAddress} loginData={loginData}/>}/>
          <Route path='/miner' element={<Miner ipAddress={ipAddress} loginData={loginData}/>}/>
          <Route path='/team' element={<Team ipAddress={ipAddress} loginData={loginData}/>}/>
          <Route path='/buss' element={<Business ipAddress={ipAddress} loginData={loginData}/>}/>
          <Route path='/profile' element={<Profile ipAddress={ipAddress} loginData={loginData}/>}/>
          <Route path='/wallet' element={<Wallet ipAddress={ipAddress} loginData={loginData}/>}/>
          <Route path='/buy' element={<Buy ipAddress={ipAddress} loginData={loginData}/>}/>
          <Route path='/topup' element={<Topup ipAddress={ipAddress} loginData={loginData}/>}/>
          <Route path="/academy" element={<Soon/>}></Route>
        </Route>
        <Route exact path="/" element = {<Login ipAddress={ipAddress} onLogin={handleLogin}/>}></Route>
        <Route path="/register" element ={<Register ipAddress={ipAddress}/>}></Route>
        <Route path="/forgot" element ={<Forgot ipAddress={ipAddress}/>}></Route>
        <Route path="*" element ={<Comming/>}></Route>
        
        
        
      </Routes>
    </BrowserRouter>
    
    </>
  );
}

export default App;
