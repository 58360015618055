import React from "react";
import { Link } from "react-router-dom";
import { Button, Input, Label } from "react"
import Logo from "../assets/img/logo.webp";


export default function Comming() {


  return (
    <div className="auth-form flex-column">
      <div className="auth-form-header text-center">
        <img src={Logo} alt="logo" height="40px" />
      </div>
      <div className="auth-form__wrapper text-center">
          <h3 className=" mt-2 text-center">Comming Soon</h3>
          <Link to="/" className="btn">Login Now</Link>
      </div>
    </div>
  );
};

