import React, { useEffect, useState, useCallback } from "react";
import {
    Badge,
    Button,
    Col,
    Collapse,
    Container,
    Input,
    Modal,
    ModalBody,
    Row,
    Spinner,
  } from "reactstrap";
import ReactLoader from '../component/ReactLoader';
import Header from "../common/header";
import { Link } from "react-router-dom";
import DollarIco from "../assets/img/icon.webp";
import axios from 'axios';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
const config = require('../config.json')
  export default function Statement({ipAddress, loginData }) {
    const [loading, setLoading] = useState(false)
    const [businessData, setBusinessData] = useState({})
    const handelTeam = useCallback(async() => {
        setLoading(true)
      let data = JSON.stringify({
          "address": loginData.address,
          "ip": ipAddress,
          "ulid": loginData.ulid
      });

      let axiosConfig = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${config.baseUrl}earning`,
          headers: {
              'address': loginData?.address,
              'ip': ipAddress,
              'ulid': loginData.ulid,
              'auth': loginData.auth,
              'token': loginData.token,
              'Content-Type': 'application/json'
          },
          data: data
      };
      axios.request(axiosConfig)
          .then((response) => {
              console.log(response.data)
              if (response.data?.status) {
                  
                setBusinessData(response.data?.info)
              }
              else {
                  Swal.fire({
                      icon: 'warning',
                      title: 'BlockHASH',
                      text: response.data?.message
                  });
              }

              setLoading(false)
          })
          .catch((error) => {
              console.log(error);
          });
    },[])
    useEffect(() => {
        handelTeam()
        
    }, [handelTeam])
    return (
        <div>
            <Header/>
            {loading ? <><ReactLoader/></> :
            <div className="mb-3 mb-md-0"  style={{ overflowY: "auto",  marginBottom: "50px"}} >
                <div className="dashboard mb-5 mb-md-0 main-p">
                  <Container className="p-0">
                    <div className="mb-2">
                        <Button className="bg-site-primary" block style={{color:"white"}}>
                            Statement
                        </Button>
                    </div>
                    <Row>
                        <Col md="4">
                            <div className="card mb-2">
                                <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                    <p className="mb-0 filter__card-label">Total Leverage</p>
                                    <h6
                                        className="mb-0  text-site-primary"
                                        style={{ fontSize: "17px" }}
                                    >
                                        $ {businessData?.limit}
                                        
                                    </h6>
                                    </div>
                                    <img src={DollarIco} alt="dollar" height="35px" />
                                </div>
                                </div>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="card mb-2">
                                <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                    <p className="mb-0 filter__card-label">Total Reward</p>
                                    <h6
                                        className="mb-0  text-site-primary"
                                        style={{ fontSize: "17px" }}
                                    >
                                       $ {businessData?.total}
                                    </h6>
                                    </div>
                                    <img src={DollarIco} alt="dollar" height="35px" />
                                </div>
                                </div>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="card mb-2">
                                <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                    <p className="mb-0 filter__card-label">Total Invested in Minner</p>
                                    <h6
                                        className="mb-0  text-site-primary"
                                        style={{ fontSize: "17px" }}
                                    >
                                        $ {businessData?.invest}
                                    </h6>
                                    </div>
                                    <img src={DollarIco} alt="dollar" height="35px" />
                                </div>
                                </div>
                            </div>
                        </Col>
                        <Col md="4">
                            <Link to={'/mining'}>
                            <div className="card mb-2">
                                <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                    <p className="mb-0 filter__card-label">Total Miner Reward</p>
                                    <h6
                                        className="mb-0  text-site-primary"
                                        style={{ fontSize: "17px" }}
                                    >
                                       $ {businessData?.minerReward}
                                    </h6>
                                    </div>
                                    <img src={DollarIco} alt="dollar" height="35px" />
                                </div>
                                </div>
                            </div>
                            </Link>
                        </Col>
                        <Col md="4">
                            <Link to={'/mining'}>
                            <div className="card mb-2">
                                <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                    <p className="mb-0 filter__card-label">Total Direct Reward</p>
                                    <h6
                                        className="mb-0  text-site-primary"
                                        style={{ fontSize: "17px" }}
                                    >
                                       $ {businessData?.directReward}
                                    </h6>
                                    </div>
                                    <img src={DollarIco} alt="dollar" height="35px" />
                                </div>
                                </div>
                            </div>
                            </Link>
                        </Col>
                        <Col md="4">
                            <Link to={"/community"}>
                            <div className="card mb-2">
                                <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                    <p className="mb-0 filter__card-label">Total Community Reward</p>
                                    <h6
                                        className="mb-0  text-site-primary"
                                        style={{ fontSize: "17px" }}
                                    >
                                        $ {businessData?.commuReward}
                                    </h6>
                                    </div>
                                    <img src={DollarIco} alt="dollar" height="35px" />
                                </div>
                                </div>
                            </div>
                            </Link>
                        </Col>
                        <Col md="4">
                            <Link to={'/vip'}>
                            <div className="card mb-2">
                                <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                    <p className="mb-0 filter__card-label">Total VIP Reward</p>
                                    <h6
                                        className="mb-0  text-site-primary"
                                        style={{ fontSize: "17px" }}
                                    >
                                        $ {businessData?.vipReward}
                                    </h6>
                                    </div>
                                    <img src={DollarIco} alt="dollar" height="35px" />
                                </div>
                                </div>
                            </div>
                            </Link>
                        </Col>
                        <Col md="4">
                            <Link to={'/global'}>
                            <div className="card mb-2">
                                <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                    <p className="mb-0 filter__card-label">Total Global Reward</p>
                                    <h6
                                        className="mb-0  text-site-primary"
                                        style={{ fontSize: "17px" }}
                                    >
                                        $ {businessData?.gbReward}
                                    </h6>
                                    </div>
                                    <img src={DollarIco} alt="dollar" height="35px" />
                                </div>
                                </div>
                            </div>
                            </Link>
                        </Col>
                        <Col md="4">
                            <div className="card mb-2">
                                <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                    <p className="mb-0 filter__card-label">Total Withdrawal</p>
                                    <h6
                                        className="mb-0  text-site-primary"
                                        style={{ fontSize: "17px" }}
                                    >
                                        {" "}
                                        $ 0
                                        
                                    </h6>
                                    </div>
                                    <img src={DollarIco} alt="dollar" height="35px" />
                                </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                  </Container>

                </div>
            </div>
            }
        </div>
    );
  }