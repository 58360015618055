import React, { useEffect, useState, useCallback } from "react";
import {
    Badge,
    Button,
    Col,
    Collapse,
    Container,
    Input,
    Modal,
    ModalBody,
    Row,
    Spinner,
  } from "reactstrap";
import ReactLoader from '../component/ReactLoader';
import Header from "../common/header";
import { Link } from "react-router-dom";
import DollarIco from "../assets/img/icon.webp";
import TeamIcon from "../assets/img/profile.webp";
import axios from 'axios';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
const config = require('../config.json')
  export default function Business({ipAddress, loginData }) {
    const [loading, setLoading] = useState(false)
    const [businessData, setBusinessData] = useState({})
    const handelTeam = useCallback(async() => {
        setLoading(true)
      let data = JSON.stringify({
          "address": loginData.address,
          "ip": ipAddress,
          "ulid": loginData.ulid
      });

      let axiosConfig = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${config.baseUrl}business`,
          headers: {
              'address': loginData?.address,
              'ip': ipAddress,
              'ulid': loginData.ulid,
              'auth': loginData.auth,
              'token': loginData.token,
              'Content-Type': 'application/json'
          },
          data: data
      };
      axios.request(axiosConfig)
          .then((response) => {
              console.log(response.data)
              if (response.data?.status) {
                  
                setBusinessData(response.data?.info)
              }
              else {
                  Swal.fire({
                      icon: 'warning',
                      title: 'BlockHASH',
                      text: response.data?.message
                  });
              }

              setLoading(false)
          })
          .catch((error) => {
              console.log(error);
          });
    },[])
    useEffect(() => {
        handelTeam()
        
    }, [handelTeam])
    return (
        <div>
            <Header/>
            {loading ? <><ReactLoader/></> :
            <div className="mb-3 mb-md-0"  style={{ overflowY: "auto",  marginBottom: "50px"}} >
                <div className="dashboard mb-5 mb-md-0 main-p">
                  <Container className="p-0">
                    <div className="mb-2">
                        <Button className="bg-site-primary" block style={{color:"white"}}>
                            Business
                        </Button>
                    </div>
                    <Row className="mt-2">
                        <Col md="4">
                            <div className="card">
                            <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <p className="mb-0 filter__card-label">
                                    Direct Team Partners
                                    </p>
                                    <h6 className="mb-0  text-site-primary number">
                                    {businessData?.directTeamCount}                                  
                                    </h6>
                                </div>
                                <img src={TeamIcon} alt="dollar" height="35px" />
                                </div>
                            </div>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="card mt-2 mt-md-0">
                            <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <p className="mb-0 filter__card-label">Total Business</p>
                                    <h6 className="mb-0  text-site-primary number">
                                    {businessData?.totalBusinessCount}
                                    </h6>
                                </div>
                                <img src={DollarIco} alt="dollar" height="35px" />
                                </div>
                            </div>
                            </div>
                        </Col>
                        
                        <Col md="4">
                            <div className="card mt-2">
                            <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <p className="mb-0 filter__card-label">Top Team Business</p>
                                    <h6 className="mb-0  text-site-primary number">
                                        {businessData?.topTeamBusinessCount}
                                    </h6>
                                </div>
                                <img src={DollarIco} alt="dollar" height="35px" />
                                </div>
                            </div>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="card mt-2">
                            <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <p className="mb-0 filter__card-label">
                                    2nd Top Team Business
                                    </p>
                                    <h6 className="mb-0  text-site-primary number">
                                        {businessData?.secondTopTeamBusinessCount}
                                    </h6>
                                </div>
                                <img src={DollarIco} alt="dollar" height="35px" />
                                </div>
                            </div>
                            </div>
                        </Col>
                        <Col md="4">
                            <div className="card mt-2">
                            <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <p className="mb-0 filter__card-label">
                                    Other Team Business
                                    </p>
                                    <h6 className="mb-0  text-site-primary number">
                                        {businessData?.otherTeamBusinessCount}
                                    </h6>
                                </div>
                                <img src={DollarIco} alt="dollar" height="35px" />
                                </div>
                            </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                    { businessData?.dr?.map( (bd, idx) => 
                        (
                            <Col md="4" className="mt-2" key={idx+1}>
                                <div className="small-card h-100">
                                <div className="d-flex align-items-center justify-content-between">
                                    <p className="mb-0 filter__card-label ">
                                    Direct Associate { idx+1}:{" "}
                                    
                                    </p>
                                    <p className="mb-0 filter__card-label ">
                                    Invest: ${bd?.invest}
                                    </p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-1">
                                    <p className="mb-0 filter__card-label">
                                    Total Team Business
                                    </p>
                                    <p className="mb-0 filter__card-label">
                                    ${bd?.buss}
                                    </p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-1">
                                    <p className="mb-0 filter__card-label">User ID</p>
                                    <p className="mb-0 filter__card-label">
                                    {bd?.ulid}
                                    </p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-1">
                                    <p className="mb-0 filter__card-label">
                                    Name of Associate
                                    </p>
                                    <p className="mb-0 filter__card-label">
                                    {bd?.name}
                                    </p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-1">
                                    <p className="mb-0 filter__card-label">
                                    Contact Number
                                    </p>
                                    <p className="mb-0 filter__card-label">
                                    {bd?.mobile}
                                    </p>
                                </div>
                                </div>
                            </Col>
                        )
                    )}
                            
                         
                    </Row>
                  </Container>
                </div>
            </div>
            }
        </div>
    );
  }