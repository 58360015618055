import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Label, Spinner } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Logo from "../assets/img/logo.webp";


export default function Forgot() {
  const navigate = useNavigate();
  
  return (
    <div className="auth-form flex-column">
      <div className="auth-form-header text-center">
        <img src={Logo} alt="logo" height="40px" />
      </div>
      <div className="auth-form__wrapper">

       
          
          <div className="text-center " style={{display:"none"}}>
            <h6 className="auth-form__wrapper-title mt-2 text-center">Welcome to BLOCKHash</h6>
                <p>Dear : Name</p>
                <p>User ID : Email</p>
                <p>Password : Email</p>
                <p className="text-center mb-0 mt-2">
                <small className="pointer" onClick={() => navigate("/")}>
                    Login Now
                </small>
                </p>
            </div>
          <div>
            <h6 className="auth-form__wrapper-title mt-2 text-center">Forgot Password</h6>
            <div className="">
            <Label className="auth-form__wrapper-label">Smart Chain Address</Label>
                <Input
                placeholder="Please enter Smart Chain Address"
                required
                id="address"
                />
                <Label className="auth-form__wrapper-label mt-1">User Id</Label>
                <Input
                placeholder="Please enter your id"
                required
                id="userId"
                />
                <Label className="auth-form__wrapper-label mt-1">Name</Label>
                <Input
                placeholder="Please enter your name"
                required
                />
                <Label className="auth-form__wrapper-label mt-1">Email</Label>
                <Input
                placeholder="Please enter your email"
                required
                type="email"
                />
                <Label className="auth-form__wrapper-label mt-1">
                Contact Number
                </Label>
                <PhoneInput
                buttonClass="button-class"
                inputClass="input-class"
                country={"in"}
                required
                />
                <Button
                block
                className="mt-3 bg-site-primary text-white"
                type="submit"
                >
                {"Get Password"}
                </Button>
                <p className="text-center mb-0 mt-2">
                <small className="pointer" onClick={() => navigate("/")}>
                    Login
                </small>
                </p>
            </div>
          </div>
        
      </div>
    </div>
  );
};

