import { useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

function PrivateRoute({ component: Component }) {
  let isLoggedIn = false
  const loginData = JSON.parse(localStorage.getItem('loginData'));
  if (loginData && loginData?.auth.length > 0) {
    isLoggedIn = true
    // console.log(loginData)
  }
  
    
  return (
         isLoggedIn ? (
          <Outlet/>
        ) : (
          <Navigate to={{ pathname: '/'}} />
        )
      )
}

export default PrivateRoute;