import React, { useEffect, useState, useCallback } from "react";
import {
    
    Button,
    Col,
    Container,
    Input,
    Row,
  } from "reactstrap";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ReactLoader from '../component/ReactLoader';
import Header from "../common/header";
import DollarIco from "../assets/img/icon.webp";
import axios from 'axios';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
const config = require('../config.json')
  export default function Topup({ipAddress, loginData }) {
    const [loading, setLoading] = useState(false)
    const [userid, setUserId] = useState('')
    const [amt, setAmt] = useState(0)
    const [min, setMin] = useState(0)
    const [is, setis] = useState(false)
    const [ds, setDs] = useState(false)
    const [th, setTh] = useState(false)
    const [rh, setRh] = useState(false)
    const [address, setAddress] = useState('')
    const [businessData, setBusinessData] = useState({})
    const handelTeam = useCallback(async() => {
      setLoading(true)
    let data = JSON.stringify({
        "address": loginData.address,
        "ip": ipAddress,
        "ulid": loginData.ulid
    });

    let axiosConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${config.baseUrl}balance`,
        headers: {
            'address': loginData?.address,
            'ip': ipAddress,
            'ulid': loginData.ulid,
            'auth': loginData.auth,
            'token': loginData.token,
            'Content-Type': 'application/json'
        },
        data: data
    };
    axios.request(axiosConfig)
        .then((response) => {
            console.log(response.data)
            if (response.data?.status) {
              setBusinessData(response.data?.info)
              setAddress(response.data?.info?.address)
              setis(response.data?.info?.is)
              setMin(response.data?.info?.minto)
            }
            else {
                Swal.fire({
                    icon: 'warning',
                    title: 'BlockHASH',
                    text: response.data?.message
                });
            }
            setLoading(false)
        })
        .catch((error) => {
            console.log(error);
        });
  },[ipAddress, loginData.address, loginData.auth, loginData.token, loginData.ulid])
  useEffect(() => {
    handelTeam()
}, [handelTeam])

    const setCopy = (e) =>{
      toast.info("Address Copy to your clipboard")
    }
    const handaleId = (e) =>{
      e.preventDefault()
      if(userid.length < 5)
      {
        toast.error("Please Enter Valid User Id")
        return;
      }
      let data = JSON.stringify({
        "address": loginData.address,
        "ip": ipAddress,
        "ulid": loginData.ulid,
        "toid" :  userid
    });
    
    let axiosConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${config.baseUrl}checkdown`,
        headers: {
            'address': loginData?.address,
            'ip': ipAddress,
            'ulid': loginData.ulid,
            'auth': loginData.auth,
            'token': loginData.token,
            'Content-Type': 'application/json'
        },
        data: data
    };
    axios.request(axiosConfig)
        .then((response) => {
            console.log(response.data)
            
            if (response.data?.status) {
              toast.info(response.data?.message);
            }
            else {
                Swal.fire({
                    icon: 'warning',
                    title: 'BlockHASH',
                    text: response.data?.message
                });
                setUserId('')
            }

           
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const handaleTrans = (e) => {
      e.preventDefault();
      setAmt(parseFloat(amt))
      if(amt < min )
      {
        toast.warn("Please enter valid amount to transfer")
        return;
      }
      if(userid.length < 5)
      {
        toast.error("Please Enter Valid User Id")
        return;
      }
      let data = JSON.stringify({
        "address": loginData.address,
        "ip": ipAddress,
        "ulid": loginData.ulid,
        "amt" :  amt,
        "toid"  : userid
    });
    setLoading(true)
    let axiosConfig = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${config.baseUrl}transferother`,
        headers: {
            'address': loginData?.address,
            'ip': ipAddress,
            'ulid': loginData.ulid,
            'auth': loginData.auth,
            'token': loginData.token,
            'Content-Type': 'application/json'
        },
        data: data
    };
    axios.request(axiosConfig)
        .then((response) => {
            console.log(response.data)
            setLoading(false)
            if (response.data?.status) {
              toast.info(response.data?.message);
              setAmt(0);
              handelTeam();                                
            }
            else {
                Swal.fire({
                    icon: 'warning',
                    title: 'BlockHASH',
                    text: response.data?.message
                });
            }

            setLoading(false)
        })
        .catch((error) => {
            console.log(error);
        });
    }

    const handalds = (e) =>{
      e.preventDefault()
      setDs(true)
      setTh(false)
      setRh(false)
    }
    const handalth = (e) =>{
      e.preventDefault()
      setDs(false)
      setTh(true)
      setRh(false)
    }
    const handalrh = (e) =>{
      e.preventDefault()
      setDs(false)
      setTh(false)
      setRh(true)
    }
    return (
        <div>
            <Header/>
            {loading ? <><ReactLoader/></> :
            <div className="mb-3 mb-md-0"  style={{ overflowY: "auto",  marginBottom: "50px"}} >
                <div className="dashboard mb-5 mb-md-0 main-p">
                  <Container className="p-0">
                    <div className="mb-2">
                        <Button className="bg-site-primary" block style={{color:"white"}}>
                            TopUp Options
                        </Button>
                    </div>
                    <Row>
                        
                        <Col md="12">
                            <div className="card mb-2">
                                <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                      <p className="mb-0 filter__card-label">TopUp Wallet Balance</p>
                                      <h6 className="mb-0  text-site-primary" style={{ fontSize: "17px" }}>
                                      ${businessData?.top}
                                      </h6>
                                    </div>
                                    <img src={DollarIco} alt="dollar" height="35px" />
                                </div>
                                </div>
                            </div>
                        </Col>
                        <Col md="6" className="mt-2 mt-md-0">
                            <div className=" card h-100 mt-2">
                                <div className=" card__header border-radius-top">
                                    Fund Transfer add to TopUp Wallet
                                </div>
                                <div className=" card__body border-radius-bottom">
                                
                                    <div className="d-flex align-items-center justify-content-between">
                                    <h2 className="mb-2  card__body-label">
                                        Deposit Address
                                    </h2>
                                    <h2 className="mb-2  card__body-label text-site-primary">
                                        BEP20 - USDT
                                    </h2>
                                    </div>
                                    <div className="text-center mt-2">
                                      <img src={businessData?.qrcode} alt={address}  height="100px" />

                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mt-1">
                                    <Input
                                        placeholder="0"
                                        className="m-2"
                                        readOnly
                                        value={address}
                                    />
                                    </div>
                                    <div className="text-center mt-3">
                                    <CopyToClipboard text={businessData?.address}
                                        onCopy={() => setCopy(true)}
                                        className="bg-primary px-5 text-white btn"
                                    >
                                        <span>Copy Address</span>
                                        
                                    </CopyToClipboard>
                                    
                                    <p className="mt-2 mb-2  card__body-note">
                                        Note: Can't Withdraw TopUp Wallet Balance, Please User your Own address to Deposit Amount another address not fetch you amount.
                                        Please use USDT BEP-20 Only. Other token not Accepeted.
                                    </p>
                                    </div>
                                
                                </div>
                            </div>
                        </Col>
                        {(is)?
                        <Col md="6" className="mt-2 mt-md-0">
                            <div className=" card h-100 mt-2">
                                <div className=" card__header border-radius-top">
                                    Fund Transfer Request to Other User
                                </div>
                                <div className=" card__body border-radius-bottom">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <h2 className="mb-2  card__body-label">
                                        TopUp Wallet
                                    </h2>
                                    <h2 className="mb-2  card__body-label text-site-primary">
                                      ${businessData?.top}
                                    </h2>
                                  </div>
                                  <div className="d-flex align-items-center justify-content-between mt-1">
                                    <Input
                                        placeholder="User ID"
                                        className="m-2"
                                        required
                                        value={userid}
                                        onChange={(e) => setUserId(e.target.value)}
                                        onBlur={handaleId}
                                    />
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mt-1">
                                    <Input
                                        placeholder="0"
                                        className="m-2"
                                        required
                                        value={amt}
                                        onChange={(e) => setAmt(e.target.value)}
                                    />
                                    </div>
                                    <div className="text-center mt-3">
                                    <Button
                                        className="bg-primary px-5 text-white"
                                        type="submit"
                                        onClick={handaleTrans}
                                    >
                                        Transfer Now
                                    </Button>
                                    <p className="mt-2 mb-2  card__body-note">
                                        Note: Can't Withdraw TopUp Wallet Balance. Only downline user get balacne
                                    </p>
                                    </div>
                                
                                </div>
                            </div>
                        </Col>
                        :<></>}
                    </Row>
                    <Row>
                        <Col md="12" className="mt-2">
                            <div className=" h-100 mt-2">
                                
                                    <div className="d-flex align-items-center justify-content-between mt-1">
                                    <Button
                                        className="bg-primary text-white me-2 btn-sm"
                                        style={{fontSize: '10px'}}
                                        type="submit"
                                        onClick={handalds}
                                    >
                                        Deposit History
                                    </Button>
                                    <Button
                                        className="bg-primary text-white "
                                        style={{fontSize: '10px'}}
                                        type="submit"
                                        onClick={handalth}
                                    >
                                        Transfer History
                                    </Button>
                                    <Button
                                        className="bg-primary text-white "
                                        style={{fontSize: '10px'}}
                                        type="submit"
                                        onClick={handalrh}
                                    >
                                        Received History
                                    </Button>
                                    </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                      {(ds)?
                        businessData?.self?.map((tr, idx)=>(
                        <Col md="4" className="mt-2" key={idx+1}>
                          <div className="small-card-ns h-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <h2 className="mb-0 withdraw-card__body-label">
                                Date
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label">
                                {tr.dt}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Transaction Amount
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                ${parseFloat(tr.amt).toFixed(3)}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Status
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                              {tr.status}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Tx-hash:
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                <a
                                  href={`https://bscscan.com/tx/${tr.hash}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View Transaction
                                </a>
                              </h2>
                            </div>
                          </div>
                        </Col>
                        ))
                        :<></>}
                      {(th)?
                        businessData?.transfer?.map((th, idx)=>(
                        <Col md="4" className="mt-2" key={idx+2}>
                          <div className="small-card-ns h-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <h2 className="mb-0 withdraw-card__body-label">
                                Date
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label">
                                {th.dt}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Type
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                Transfer
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                USER ID
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                {th.user_lid}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Name
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                {th.user_name}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Transfer Amount
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                ${parseFloat(th.amt).toFixed(3)}
                              </h2>
                            </div>
                          </div>
                        </Col>
                        ))
                      :<></>}
                      {(rh)?
                      businessData?.recive?.map((th, idx)=>(
                        <Col md="4" className="mt-2" key={idx+2}>
                          <div className="small-card-ns h-100">
                            <div className="d-flex align-items-center justify-content-between">
                              <h2 className="mb-0 withdraw-card__body-label">
                                Date
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label">
                                {th.dt}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Type
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                Recived
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                USER ID
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                {th.user_lid}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Name
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                {th.user_name}
                              </h2>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mt-1">
                              <h2 className="mb-0 withdraw-card__body-label text-site-gray">
                                Transfer Amount
                              </h2>
                              <h2 className="mb-0 withdraw-card__body-label text-site-primary">
                                ${parseFloat(th.amt).toFixed(3)}
                              </h2>
                            </div>
                          </div>
                        </Col> ))
                      :<></>}
                    </Row>
                  </Container>

                </div>
            </div>
            }
        </div>
    );
  }