import React, { useEffect, useState, useCallback } from "react";
import {
    Badge,
    Button,
    Col,
    Collapse,
    Container,
    Input,
    Modal,
    ModalBody,
    Row,
    Spinner,
  } from "reactstrap";
import ReactLoader from '../component/ReactLoader';
import Header from "../common/header";
import DollarIco from "../assets/img/icon.webp";
import axios from 'axios';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
const config = require('../config.json')
  export default function Buy({ipAddress, loginData }) {
    const [loading, setLoading] = useState(false)
    const [packs, setPackages] = useState({})
    const [pack, setPack] = useState({})
    const [is, setis] = useState(false)
    const [amt, setamt] = useState(0)
    const handelPack = useCallback(async() => {
        setLoading(true)
      let data = JSON.stringify({
          "address": loginData.address,
          "ip": ipAddress,
          "ulid": loginData.ulid
      });

      let axiosConfig = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${config.baseUrl}packagebuy`,
          headers: {
              'address': loginData?.address,
              'ip': ipAddress,
              'ulid': loginData.ulid,
              'auth': loginData.auth,
              'token': loginData.token,
              'Content-Type': 'application/json'
          },
          data: data
      };
      axios.request(axiosConfig)
          .then((response) => {
              console.log(response.data)
              if (response.data?.status) {
                  
                setPackages(response.data?.info)
              }
              else {
                  Swal.fire({
                      icon: 'warning',
                      title: 'BlockHASH',
                      text: response.data?.message
                  });
              }

              setLoading(false)
          })
          .catch((error) => {
              console.log(error);
          });
    },[ipAddress, loginData.address, loginData.auth, loginData.token, loginData.ulid])
    useEffect(() => {
        handelPack()
        
    }, [handelPack])

    function selectPack(e, pk) {
        e.preventDefault();
        
        if(parseFloat(packs?.top) < parseFloat(pk.minamt))
        {
            toast.warn("Select a valid miner");
            return;
        }
        setPack(pk);
        setis(true);
    }

    const handerBuy = (e) => {
        e.preventDefault();
        let te = true;
        te = (parseFloat(amt) >= parseFloat(pack.fminamt));
        te = te && (parseFloat(amt) <= parseFloat(pack.fmaxamt));
        te = te && (parseFloat(packs.top) >= parseFloat(pack.minamt));
        if(!te)
        {
            toast.warn("Please enter valid Amount");
            return;
        }
        else
        {
            let data = JSON.stringify({
                "address": loginData.address,
                "ip": ipAddress,
                "ulid": loginData.ulid,
                "amt" :  amt,
                "pack" : pack.id  
            });
            Swal.fire({
                title: "Do you want to Buy Package?",
                showDenyButton: true,
                confirmButtonText: "Buy Now",
                denyButtonText: `Don't Buy`
            }).then((result) => {
                
                if (result.isConfirmed) {
                    setLoading(true)
                    let axiosConfig = {
                        method: 'post',
                        maxBodyLength: Infinity,
                        url: `${config.baseUrl}buy`,
                        headers: {
                            'address': loginData?.address,
                            'ip': ipAddress,
                            'ulid': loginData.ulid,
                            'auth': loginData.auth,
                            'token': loginData.token,
                            'Content-Type': 'application/json'
                        },
                        data: data
                    };
                    axios.request(axiosConfig)
                        .then((response) => {
                            console.log(response.data)
                            setLoading(false)
                            if (response.data?.status) {
                            toast.info(response.data?.message);
                            setamt(0)
                            setis(false)
                            setPack({})
                            handelPack()                                
                            }
                            else {
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'BlockHASH',
                                    text: response.data?.message
                                });
                            }
            
                            setLoading(false)
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else if (result.isDenied) {
                toast.warn('You cancel Buy request')
                setamt(0)
                setis(false)
                setPack({})
                }
            });
        }
    }
    return (
        <div>
            <Header/>
            {loading ? <><ReactLoader/></> :
            <div className="mb-3 mb-md-0"  style={{ overflowY: "auto",  marginBottom: "50px"}} >
                <div className="dashboard mb-5 mb-md-0 main-p">
                  <Container className="p-0">
                    <div className="mb-2">
                        <Button className="bg-site-primary" block style={{color:"white"}}>
                            Buy Mining Package
                        </Button>
                    </div>
                    <Row>
                        <Col md="6">
                            <div className="card mb-2">
                                <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                    <p className="mb-0 filter__card-label">TopUp Wallet Balance</p>
                                    <h6 className="mb-0  text-site-primary"  style={{ fontSize: "17px" }} >
                                      $ {packs?.top}                                        
                                    </h6>
                                    </div>
                                    <img src={DollarIco} alt="dollar" height="35px" />
                                </div>
                                </div>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="card mb-2">
                                <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                    <p className="mb-0 filter__card-label">Invested Amount</p>
                                    <h6
                                        className="mb-0  text-site-primary"
                                        style={{ fontSize: "17px" }}
                                    >
                                        $ {packs?.toinv}
                                    </h6>
                                    </div>
                                    <img src={DollarIco} alt="dollar" height="35px" />
                                </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {(!is)?
                    <Row>
                    {packs?.packs?.map((dt, idx)=> (
                        <Col md="4" className="mt-2 mt-md-0" key={idx+1}>
                            <div className=" card h-100 mt-2">
                                <div className=" card__header border-radius-top bg-primary text-white">
                                    {dt?.package_name} (with Insurance Fund)
                                </div>
                                <div className=" card__body border-radius-bottom">
                                
                                    <div className="d-flex align-items-center justify-content-between">
                                    <h2 className="mb-2  card__body-label">
                                        Miner Amount 
                                    </h2>
                                    <h2 className="mb-2  card__body-label text-site-primary">
                                        ${parseFloat(dt.fminamt).toFixed(3)} To ${parseFloat(dt.fmaxamt).toFixed(3)}
                                    </h2>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                    <h2 className="mb-2  card__body-label">
                                        Mining return 
                                    </h2>
                                    <h2 className="mb-2  card__body-label text-site-primary">
                                        {dt?.per}% Monthely
                                    </h2>
                                    </div>
                                    <div className="text-center mt-3">
                                    <Button className="bg-primary px-5 text-white"
                                        type="submit" 
                                        onClick={(e) => selectPack(e, dt)}
                                        >
                                        Buy Now
                                    </Button>
                                    
                                    </div>
                                
                                </div>
                            </div>
                        </Col>
                        ))}
                    </Row>
                    :
                    <Row>
                    <Col md="12" className="mt-2 mt-md-0" >
                            <div className=" card h-100 mt-2">
                                <div className=" card__header border-radius-top">
                                    Buy Miner 
                                </div>
                                <div className=" card__body border-radius-bottom">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h2 className="mb-2  card__body-label">
                                        {pack.package_name} 
                                        </h2>
                                        <h2 className="mb-2  card__body-label text-site-primary">
                                        ${parseFloat(pack.fminamt).toFixed(3)} To ${parseFloat(pack.fmaxamt).toFixed(3)}
                                        </h2>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h2 className="mb-2  card__body-label">
                                        TopUp Wallet
                                        </h2>
                                        <h2 className="mb-2  card__body-label text-site-primary">
                                        ${packs?.top}
                                        </h2>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mt-1">
                                    <Input
                                        placeholder="0"
                                        className="m-2"
                                        required
                                        onChange={(e) => setamt(e.target.value)}
                                        value={amt}
                                    />
                                    </div>
                                    <div className="text-center mt-3">
                                    <Button
                                        className="bg-primary px-5 text-white"
                                        onClick={handerBuy}
                                        type="submit"
                                    >
                                        Buy Now
                                    </Button>
                                    </div>
                                
                                </div>
                            </div>
                        </Col>
                </Row>}
                <Row>
                        {packs?.invest?.map((dt, idx)=>(
                        <Col key={idx+1} md="4" className="mt-2">
                            <div className="card h-100 mt-2">
                                <div className="card__body border-radius-all">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                    <h2 className="card__body-label text-site-gray me-1 mb-0">
                                        Miner
                                    </h2>
                                    </div>
                                    <h2 className="card__body-date mb-0">
                                    {dt?.pname}
                                    </h2>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                    <h2 className="card__body-label text-site-gray me-1 mb-0">
                                        Date:
                                    </h2>
                                    </div>
                                    <h2 className="card__body-date mb-0">
                                    {dt?.dt}
                                    </h2>
                                </div>
                                <div className="d-flex mt-1 align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                    <h2 className="card__body-label text-site-gray me-1 mb-0">
                                        Amount:
                                    </h2>
                                    </div>
                                    <div className="d-flex align-items-center">
                                    <h2 className="card__body-price mb-0">
                                        ${parseFloat(dt?.net_amount).toFixed(3)}
                                    </h2>
                                    </div>
                                </div>
                                <div className="d-flex mt-1 align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                    <h2 className="card__body-label text-site-gray me-1 mb-0">
                                        Insurance Amount:
                                    </h2>
                                    </div>
                                    <div className="d-flex align-items-center">
                                    <h2 className="card__body-price mb-0">
                                        ${parseFloat(dt?.ins_amt).toFixed(3)}
                                    </h2>
                                    </div>
                                </div>
                                <div className="d-flex mt-1 align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                    <h2 className="card__body-label text-site-gray me-1 mb-0">
                                        Miner Amount:
                                    </h2>
                                    </div>
                                    <div className="d-flex align-items-center">
                                    <h2 className="card__body-price mb-0">
                                        ${parseFloat(dt?.invest_amount).toFixed(3)}
                                    </h2>
                                    </div>
                                </div>
                                <div className="d-flex mt-1 align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                    <h2 className="card__body-label text-site-gray me-1 mb-0">
                                        Miner Return:
                                    </h2>
                                    </div>
                                    <div className="d-flex align-items-center">
                                    <h2 className="card__body-price mb-0">
                                        {dt?.roi}% Monthly
                                    </h2>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </Col>
                        ))}
                    </Row>
                  </Container>

                </div>
            </div>
            }
        </div>
    );
  }