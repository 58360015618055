import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input, Label } from "reactstrap";
import axios from 'axios';
import Swal from 'sweetalert2'
//import { toast } from 'react-toastify';
import "react-phone-input-2/lib/style.css";
import Logo from "../assets/img/logo.webp";
const config = require('../config.json')

export default function Login({ ipAddress, onLogin }) {
  const navigate = useNavigate();
  const [lid, setlid] = useState('')
  const [pass, setpass] = useState('')
  const [showPass, setshowPass] = useState(false)
  const handeldata = () =>{
    let axiosConfig = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${config.baseUrl}data`,
      headers: {
          'Content-Type': 'application/json'
      }
  };
  axios.request(axiosConfig)
      .then((response) => {
          //console.log(response.data)
          localStorage.setItem('appData', JSON.stringify(response.data.lst));
      })
      .catch((error) => {
          console.log(error);
      });
  }

    const handleLogin = async (event) => {
        event.preventDefault()
        login();
    }
    
    const login = async () => {
        let  ip = ipAddress
        let data = JSON.stringify({
            "ulid": lid,
            "upass": pass,
            "ip": ip
        });
        let axiosConfig = {
            method: 'post',
            url: `${config.baseUrl}`,
            headers: {
                'ulid': lid,
                'ip': ip,
                'Content-Type': 'application/json'
            },
            data: data
        };
        
        let response = await axios.request(axiosConfig)
        response = response.data
        if (response.status) {
            //console.log(response.data)
            let loginData = response.data
            loginData.address = response.data.address
            await setLoginData(loginData)
            onLogin(response.data)
            handleDash();
        }
        else if (response.code === 30) {
            Swal.fire({
                icon: 'info',
                title: 'BlockHASH',
                text: response?.message
            }).then(() => {
              navigate('/register');
            })
        }
        else {
          console.log(response?.message)
          
          Swal.fire({
            icon: 'info',
            title: 'BlockHASH',
            text: response?.message
        })
        }
      
    };
    async function setLoginData(loginData) {
        localStorage.setItem('loginData', JSON.stringify(loginData));
    }
    
    useEffect(() => {
      handeldata();
    },[ipAddress] )

    const clickHandler =(e) => {
      e.preventDefault();
      setshowPass(!showPass)
    }
    const handleDash = () => {
      
      let loginData = JSON.parse(localStorage.getItem('loginData'));
      let data = JSON.stringify({
          "address": loginData.address,
          "ip": ipAddress,
          "ulid": loginData.ulid
      });

      let axiosConfig = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${config.baseUrl}desk`,
          headers: {
              'address': loginData?.address,
              'ip': ipAddress,
              'ulid': loginData.ulid,
              'auth': loginData.auth,
              'token': loginData.token,
              'Content-Type': 'application/json'
          },
          data: data
      };
      axios.request(axiosConfig)
          .then((response) => {
              console.log(response.data)
              if (response.data?.status) {
                navigate('/home');         
              }
              else {
                  Swal.fire({
                      icon: 'warning',
                      title: 'BlockHASH',
                      text: "Please Login again"
                  });
              }

              
          })
          .catch((error) => {
              console.log(error);
          });
  }


  return (
    <div className="auth-form flex-column">
      <div className="auth-form-header text-center">
        <img src={Logo} alt="logo" height="40px" />
      </div>
      <div className="auth-form__wrapper">
          <h6 className="auth-form__wrapper-title mt-2 text-center">Login</h6>
          <div className="">
            <Label className="auth-form__wrapper-label">User Id</Label>
            <Input
              placeholder="Please enter id"
              required
              id="sponsorId"
              onChange={(e) => setlid(e.target.value)}
              value={lid}
            />
            <Label className="auth-form__wrapper-label mt-2">Password <span className={showPass ? 'text-info' : 'text-danger'} onClick={clickHandler}>{showPass ? 'Hide' : 'Show'} </span></Label>
            <Input
              placeholder="Please enter your password"
              type={showPass ? 'text' : 'password'}
              required
              value={pass} 
              onChange={(e) => setpass(e.target.value)}
            />
            <Button
              block
              className="mt-3 bg-site-primary text-white"
              type="submit"
              onClick={handleLogin}
            >
              {"Login"}
            </Button>
            <p className="text-center mb-0 mt-2">
              <small className="pointer" onClick={() => navigate("/register")}>
                Register Now
              </small>
            </p>
          </div>
        
      </div>
    </div>
  );
};

