import React, { useEffect, useState, useCallback } from "react";
import {Button, Col, Container, Input, ModalBody, Row, Modal,
    Form, FormGroup, Label,
    ModalHeader, ModalFooter
  } from "reactstrap";
import ReactLoader from '../component/ReactLoader';
import Header from "../common/header";
import sport from "../assets/img/support.webp";
import axios from 'axios';
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
const config = require('../config.json')
export default function Support({ipAddress, loginData }) {
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false);
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [data, setData] = useState('');
    const toggle = () => setModal(!modal);
    const handleTicket = (e) => {
        e.preventDefault()
        if(subject.length < 10 && message.length < 10)
        {
            toast.warn("Please enter some Subject and Message")
        }
        let data = JSON.stringify({
            "address": loginData.address,
            "ip": ipAddress,
            "ulid": loginData.ulid,
            "subject":subject,
            "message":message
            });
            let axiosConfig = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${config.baseUrl}ticket`,
                headers: {
                    'address': loginData?.address,
                    'ip': ipAddress,
                    'ulid': loginData.ulid,
                    'auth': loginData.auth,
                    'token': loginData.token,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios.request(axiosConfig)
                .then((response) => {
                    console.log(response.data)
                    if (response.data?.status) {
                      toast.info(response.data?.message);
                      handelData();
                    }
                    else {
                        Swal.fire({
                            icon: 'warning',
                            title: 'BlockHASH',
                            text: response.data?.message
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            setModal(false)
            setSubject('');
            setMessage('')
      }
      const handelData = useCallback(async() => {
        setLoading(true)
      let data = JSON.stringify({
          "address": loginData.address,
          "ip": ipAddress,
          "ulid": loginData.ulid
      });

      let axiosConfig = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${config.baseUrl}support`,
          headers: {
              'address': loginData?.address,
              'ip': ipAddress,
              'ulid': loginData.ulid,
              'auth': loginData.auth,
              'token': loginData.token,
              'Content-Type': 'application/json'
          },
          data: data
      };
      axios.request(axiosConfig)
          .then((response) => {
              console.log(response.data)
              if (response.data?.status) {
                setData(response.data)
                }
              else {
                  Swal.fire({
                      icon: 'warning',
                      title: 'BlockHASH',
                      text: response.data?.message
                  });
              }

              setLoading(false)
          })
          .catch((error) => {
              console.log(error);
          });
    },[ipAddress, loginData.address, loginData.auth, loginData.token, loginData.ulid])
    useEffect(() => {
        handelData()
    }, [handelData])
    return (
        <div>
            <Header/>
            {loading ? <><ReactLoader/></> :
            <div className="mb-3 mb-md-0"  style={{ overflowY: "auto",  marginBottom: "50px"}} >
                <div className="dashboard mb-5 mb-md-0 main-p">
                  <Container className="p-0">
                    <div className="mb-2">
                        <Button className="bg-site-primary" block style={{color:"white"}}>
                            Contact Support
                        </Button>
                    </div>
                    <Row>
                        <Col md="12">
                            <div className="card mb-2" onClick={toggle}>
                                <div className="card__body border-radius-all">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                        <p className="mb-0 filter__card-label">New Support Ticket</p>
                                        </div>
                                        <img src={sport} alt="dollar" height="35px" />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                    
                    <Col md="12">
                            <div className="card mb-2" >
                                <div className="card__body border-radius-all">
                                    <div className="text-center">
                                        <div>
                                        <p className="mb-0 filter__card-label">Response </p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </Col>

                            {data?.info?.map((itm, idx)=> (
                            <Col md="4">
                             <div className="card mb-b">
                                <div className="card__body border-radius-bottom" key={idx+2}>
                                    <div className="mt-2 d-flex align-items-center justify-content-between">
                                        <h2 className="mb-0 support-content">Date</h2>
                                        <h2 className="mb-0 support-content">
                                            {itm.adate}
                                        </h2>
                                    </div>
                                    <div className="mt-2  justify-content-between">
                                    <h2 className="mb-0 support-content me-5 text-primary">Subject</h2>
                                    <p className="mb-0 support-content ">
                                        {itm.user_subject}
                                    </p>
                                    </div>
                                    <div className="mt-2  justify-content-between">
                                    <h2 className="mb-0 support-content me-5 text-primary">Message</h2>
                                    <h2 className="mb-0 support-content ">
                                        {itm.user_message}
                                    </h2>
                                    </div>
                                    <div className="mt-2 d-flex align-items-center justify-content-between">
                                    <h2 className="mb-0 support-content">Status</h2>
                                    <h2 className="mb-0 support-content">
                                        {itm.status}
                                    </h2>
                                    </div>
                                    <div className="mt-2 d-flex align-items-center justify-content-between">
                                    <h2 className="mb-0 support-content">Date of Response</h2>
                                    <h2 className="mb-0 text-primary">
                                        {itm.rdate}
                                    </h2>
                                    </div>
                                    <div className="mt-2 ustify-content-between">
                                    <h2 className="mb-0 support-content">Response</h2>
                                    <h2 className="mb-0 support-content text-primary">
                                        {itm.reply}
                                    </h2>
                                    </div>
                                </div>
                            </div>   
                            </Col>
                        ))}
                        
                    </Row>
                  </Container>

                </div>
            </div>
            }
            <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered" size={'sm'}>
                <ModalHeader className="bg-site-secondary" style={{color:"white"}} toggle={toggle}>
                    New Ticket
                </ModalHeader>
                <ModalBody>
                <Form>
                <FormGroup className="mb-3" id="forsubject">
                    <Label>Subject</Label>
                    <Input
                        type="text"
                        placeholder="Subject"
                        autoFocus
                        onChange={e => setSubject(e.target.value)}
                    />
                    </FormGroup>
                    <FormGroup
                    className="mb-3"
                    id="exampleForm.ControlTextarea1"
                    >
                    <Label>Your Message</Label>
                    <Input  id="message"  name="text"  type="textarea" onChange={(e) =>setMessage(e.target.value)} />
                    </FormGroup>
                </Form>
                </ModalBody>
                <ModalFooter className="text-center">
                    <Button className="bg-site-secondary" style={{color:"white"}} onClick={handleTicket}>
                        Genrate Ticket
                    </Button>
                </ModalFooter>
            </Modal>
        </div>

    );
  }